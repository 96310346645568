.container {
  display: flex;
  height: 100vh;
  background-color: #cadbde;
}

#editor{
  height: 92%;
  background-color: hsl(180, 23%, 97%);
  margin-top: -15px;
  box-shadow: 0px 0px 3px 2px #859e9e;
  overflow-y: auto;
  width: 400px;
  min-width: 15vw;
  max-width: 60vw;
  max-height: 92%;
}

#preview{
  background-color: #f7fafa;
  margin-top: -15px;
  padding: 0px 20px 20px 20px;
  box-shadow: 0px 0px 3px 2px #859e9e;
  height: calc(92% - 15px);
  overflow-y: auto;
}

#middle-line{
  width: 25px;
}

.box-title{
  background-color: #8db7e0;
  box-shadow: 0px 0px 3px 2px #859e9e;
  margin-top: 0;
  color: #202f36;
}

#left-box,
#right-box{
  margin: 20px;
}

